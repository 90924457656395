import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [license] = hooks.useModel('license', [record_id], { populate: ['usual_vehicle.info'], single: true })

  const [status] = hooks.useFieldValues(['status'])
  const [, { openDialog }] = hooks.useDialogs()
  const [, setSuspendedState] = hooks.useFormState('suspended')

  const verifyClientId = useCallback(hooks.useRecordExist('license', 'license_client_id'), [])
  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])

  const color = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    record_id,
    isManagingRecord,
    color,
    usual_vehicle: license?.usual_vehicle,
    statusChange,
    verifyClientId,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
