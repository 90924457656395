import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent, Link } from '@mui/material'

import { TextField, ModelMenuSelectorField, MenuSelectorField, DateField } from '@front/squirtle'
import { isRequired } from '@front/squirtle/utils/validators'

import { SuspensionTitleForm } from '@abra/elements/Suspensions'

const Row1 = ({ isManagingRecord, color, statusChange, usual_vehicle, verifyClientId }) =>
  <Grid container spacing={2}>
    <Grid item xs>
      <TextField
        label={I18n.t('contract.id')}
        name={'license_client_id'}
        required
        afterUserChange={verifyClientId}
        debounceTime={250}
      />
    </Grid>
    <Grid item xs>
      <TextField
        label={I18n.t('contract.license_id')}
        name={'license_number'}
        required
        validate={isRequired}
      />
    </Grid>
    <Grid item xs>
      <DateField
        label={I18n.t('date.delivery')}
        name={'delivery_date'}
        inputFormat="DD/MM/YYYY"
        required
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        label={I18n.t('category.label', { count: 1 })}
        name={'billing_category'}
        model_name={'billingcategory'}
        labelKeys={['name_translated']}
        searchMode={'search'}
        loadOnMount
        multiple={false}
        validate={isRequired}
        required
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted', disabled: !isManagingRecord },
          { label: I18n.t('status.suspended'), value: 'suspended', disabled: !isManagingRecord }
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: color, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
    <Grid item xs>
      <Typography sx={{ fontSize: 13 }}> {I18n.t('vehicle.usual')} </Typography>
      <Typography>
        {!!usual_vehicle ?
          <Link href={`/adherent/${usual_vehicle?.group}/vehicle/${usual_vehicle?.vehicle_id}`} target={'_blank'}>
            {usual_vehicle?.info?.plaque}
          </Link>
          : '-'
        }
      </Typography>
    </Grid>
  </Grid>

const PrimarySection = ({
  record_id,
  isManagingRecord,
  color,
  statusChange,
  verifyClientId,
  usual_vehicle,
}) => (
  <>
    <StepLabel>
      <SuspensionTitleForm label={I18n.t('contract.label', { count: 1 })} record_id={record_id} model_name={"license"} />
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
        <Row1
          isManagingRecord={isManagingRecord}
          color={color}
          statusChange={statusChange}
          usual_vehicle={usual_vehicle}
          verifyClientId={verifyClientId}
        />
      </Box>
    </StepContent>
  </>
)


export default React.memo(PrimarySection)
