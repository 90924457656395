import React, { useCallback } from 'react'

import { FormTable, TableRow, TableHeader } from '@front/squirtle'
import { Grid } from '@mui/material'

import Header from './Header'
import Row from './Row'

const OrderHistoryList = ({ record_id, isManagingRecord, load_type }) => (
  <Grid item xs={12}>
    <FormTable
      model_name={'event'}
      model_func={'getOrderEvents'}
      config={{
        initial_filter: isManagingRecord ? { order_id: record_id } : undefined,
        forced_filter: { order_id: record_id },
        validate_filter: useCallback(() => !!isManagingRecord, [isManagingRecord]),
        watchers: [record_id, isManagingRecord],
        sort: ['producedAt ASC'],
        populate: ['operator.info', 'driver.info', 'produced_by.info'],
        default_limit: 50,
        watch_type: 'index'
      }}
      tableProps={{
        enableToolbar: false,
        enableRowActions: false,
        enableGlobalActions: false,
        allowEditMode: false,
        allowRowGenerate: false
      }}
      loadOnMount={false}
    >
      <TableHeader><Header /></TableHeader>
      <TableRow><Row load_type={load_type} /></TableRow>
    </FormTable>
  </Grid>
)


export default React.memo(OrderHistoryList)
