import React from 'react'
import { hooks } from '@front/volcanion'

import { HookUtils, BillingUtils } from '@front/volcanion/utils'

const withContainer = Component => ({ option, ...rest }) => {
  const [estimation_type, manual_price] = hooks.useFieldValues(['estimation_type', 'manual_price'])
  const [{ record_id, isReadOnly, isManagingRecord }] = hooks.useFormState()

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['quotation'] })
  const [estimation, estimation_status] = hooks.useSelectedEstimation(option?.commercial_package_id, estimation_type)

  const pricing = !!isManagingRecord && (!estimation_status?.isEstimationFetched || !!isReadOnly) ? order?.quotation : estimation?.details?.pricing

  const rate = estimation_type === 'special'
    ? BillingUtils.mergeWithCurrency(BillingUtils.getRoundedPrice(manual_price, 1))
    : BillingUtils.getEstimationDisplay(pricing, false, 1,)

  const isLoading = HookUtils.getLoadingState([estimation_status])

  const mergedProps = {
    option,
    isLoading,
    rate
  }

  return (
    <Component {...mergedProps} {...rest} />
  )
}

export default withContainer
