import React, { useCallback, useEffect, useMemo } from 'react'
import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const setFormValues = hooks.useFormChange()
  const { prefix } = props
  const [user_id] = hooks.useFormState('user_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [, setReservationInfoId] = hooks.useFormState('source_reservationinfo_id')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const guest = hooks.useFieldValue('guest')
  const [vehicle_count] = hooks.useFieldValues(['vehicle_count'])
  const [isB2B] = hooks.useFormState('isB2B')
  const [addressDetails, setAddressDetails] = hooks.useFormState(`details.${prefix}`)
  const [save_comment, setSaveComment] = hooks.useFormState(`save_comment.${prefix}`)
  const prefix_field = prefix === 'source' ? 'src' : 'dest'
  const comment = hooks.useFieldValue(`info.${prefix_field}_comment`)
  const [favorite_address, setFavoriteAddress] = hooks.useFormState(`favorite_address.${prefix}`)
  const additional_info_required = hooks.useFieldValue('is_reservationinfo')
  const [address] = hooks.useModel('address', [_.get(addressDetails, 'address_id')], {
    single: true
  })

  const [, setMeetingPoint] = hooks.useFormState('meeting_point')
  const [, setDriverInstruction] = hooks.useFormState('driver_instruction')

  useEffect(() => {
    if (!_.get(address, 'favorite')) return
    setFavoriteAddress(address)
  }, [_.get(address, 'favorite')])

  const [
    load_type,
    selected_application,
    first_name,
    last_name,
    name,
    gsm,
    companyuser_id,
    commercial_formula,
    schedule_type,
    source,
    destination
  ] = hooks.useFieldValues([
    'load_type',
    'user.application',
    'user.first_name',
    'user.last_name',
    'user.name',
    'user.gsm',
    'user.companyuser',
    'user.commercial_formula',
    'schedule_type',
    'source',
    'destination'
  ])
  const infoWatcher = [last_name, name, gsm, user_id]
  const addressFieldValue = hooks.useFieldValue(prefix)

  const [application_name] = hooks.useModel('application', [selected_application], { single: true, mutator: 'name' })
  const [toggleFavorite] = hooks.useModelFunction('address', 'toggleFavorite')

  const special_load_type = _.toLower(application_name) === 'collecto' ? _.toLower(application_name) : load_type

  const [companyuser] = hooks.useModel('companyuser', [companyuser_id], { populate: ['user.customerinfo', 'user.info', 'user.auth'], single: true })

  const [poi] = hooks.useModel('poi', [_.get(addressDetails, 'poi_id') || _.get(addressDetails, 'poi.poi_id')], { single: true, populate: ['type.reservationinfo', 'type.authorized_pois'] })

  const required_additional_info = _.get(poi, 'required_additional_info')
  const reservationinfo = _.get(poi, 'type.reservationinfo')
  const pickup_at_transport = useMemo(() => {
    const authorized_poi = _.find(_.get(poi, 'type.authorized_pois'), (a_poi) => _.get(a_poi, 'commercial_formula') === commercial_formula)
    return _.get(authorized_poi, 'pickup_at_transport')
  }, [_.map(_.get(poi, 'type.authorized_pois'), 'id').join(','), commercial_formula])

  const display_additional_info = !!reservationinfo && schedule_type === 'planned' && !!required_additional_info && !!pickup_at_transport
  const display_swap_button = prefix === 'source' && !!source && !!destination && !isReadOnly
  useEffect(() => {
    if (!!_.get(reservationinfo, 'reservationinfo_id'))
      setReservationInfoId(reservationinfo.reservationinfo_id)
    else setReservationInfoId()
  }, [_.get(reservationinfo, 'reservationinfo_id')])

  const onAddressResolve = useCallback(Callbacks.handleOnAddressResolve(setAddressDetails, setFavoriteAddress, setFormValues, prefix, source, destination, setMeetingPoint, setDriverInstruction), [setAddressDetails, setFavoriteAddress, setFormValues, prefix, source, destination, setMeetingPoint, setDriverInstruction])
  const handleReplaceComment = useCallback(Callbacks.handleReplaceCommentHandler(openNotification, save_comment, setSaveComment, comment, addressDetails), [openNotification, save_comment, setSaveComment, comment, addressDetails])
  const handleSetFavoriteAddress = useCallback(Callbacks.handleSetFavoriteAddressHandler(openNotification, user_id, addressDetails, favorite_address, setFavoriteAddress, toggleFavorite), [openNotification, user_id, addressDetails, favorite_address, setFavoriteAddress, toggleFavorite])
  const handleGuestChange = useCallback(Callbacks.guestChangeHandler(setFormValues, companyuser, prefix, load_type), [setFormValues, companyuser, prefix, load_type])
  const handleIsReservationinfoChange = useCallback(Callbacks.handleIsReservationinfoChangeHandler(poi, setMeetingPoint, setDriverInstruction), [poi, setMeetingPoint, setDriverInstruction])
  const swapAddress = useCallback(Callbacks.handleSwapAddress(source, destination, setFormValues), [source, destination, setFormValues])

  useEffect(() => {
    if (!!user_id || !!isManagingRecord || prefix !== 'source') return
    if ((!!last_name || !!name) && !!gsm)
      setFormValues([
        { field: 'loads.src_phone', value: gsm },
        {
          field: 'loads.src_contact',
          value: _.compact([first_name, last_name]).join(' ') || name
        }
      ])

  }, infoWatcher)

  const mergedProps = {
    display_swap_button,
    swapAddress,
    commercial_formula,
    schedule_type,
    handleGuestChange,
    guest,
    companyuser_id,
    guest_booking: _.get(companyuser, 'guest_booking'),
    isB2B,
    user_id,
    enabled_field: special_load_type,
    load_type,
    onAddressResolve,
    prefix,
    handleReplaceComment,
    handleSetFavoriteAddress,
    disabledIconButton: isReadOnly || !user_id || !!_.isEmpty(addressFieldValue),
    save_comment,
    favorite_address,
    isFavorite: !!_.get(address, 'favorite'),
    vehicle_count,
    reservationinfo,
    display_additional_info,
    additional_info_required,
    isReadOnly,
    handleIsReservationinfoChange,
    prefix_field,
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
