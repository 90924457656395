import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => ({
  onFieldClear: parentOnFieldClear,
  onApplicationChange: parentOnApplicationChange,
  searchGSM: parentSearchGSM,
  ...props
}) => {
  const setFormValues = hooks.useFormChange()
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [user_id, setUserIDState] = hooks.useFormState('user_id')
  const [hasCompanyAccess, setHasCompanyAccess] = hooks.useFormState('hasCompanyAccess')
  const [authentication_type, setAuthenticationTypeState] = hooks.useFormState('authentication_type')
  const [, setSelectedUser] = hooks.useFormState('selected_user')
  const [
    load_type,
    password,
    companyservice_id,
    companycontract_id,
    calling_number,
    company_id,
    companyuser_id,
    application_id,
    commercial_formula,
    passenger_number,
  ] = hooks.useFieldValues([
    'load_type',
    'user.password',
    'user.companyservice',
    'user.companycontract',
    'user.calling_number',
    'user.company',
    'user.companyuser',
    'user.application',
    'user.commercial_formula',
    'loads.src_phone',
  ])

  const [selectedCompanyusers, searchCompanyuser] = hooks.useModelSearch('companyuser', 'get', {
    search_id: 'menuselector_companyuser',
    populate: ['user.info', 'user.auth', 'user.customerinfo', 'contact'],
    forced_filter: { company: company_id },
    validate_filter: useCallback(() => !!company_id, [company_id]),
    watchers: [company_id],
  })
  const [, searchCompanycontract] = hooks.useModelSearch('companycontract', 'get')
  const [companyservice] = hooks.useModel('companyservice', [companyservice_id], { single: true, populate: ['contact'] })
  const [caller_companyusers, searchCallerCompanyuser] = hooks.useModelSearch('companyuser', 'get', {
    search_id: 'search_caller_companyuser',
    populate: ['companyuser_services', 'user.customerinfo', 'user.info', 'user.auth', 'contact'],
  })

  useEffect(() => {
    !!user_id && !!companyservice_id && searchCallerCompanyuser({ user: user_id, services: [companyservice_id] })
  }, [user_id, companyservice_id])

  const caller_companyuser = _.head(caller_companyusers)
  const selectedCompanyuser = _.find(selectedCompanyusers, ['companyuser_id', companyuser_id])
  const booking_level = _.get(caller_companyuser, 'booking_level')
  const guest_booking = !!_.has(caller_companyuser, 'guest_booking') ? _.get(caller_companyuser, 'guest_booking') : undefined
  const self_booking = _.get(caller_companyuser, 'self_booking')

  const setUserInfo = useCallback(Callbacks.setUserInfoHandler(setFormValues, setUserIDState, setAuthenticationTypeState)
    , [setFormValues, setUserIDState, setAuthenticationTypeState])

  const onFieldClear = useCallback(Callbacks.onFieldClearHandler(parentOnFieldClear), [parentOnFieldClear])
  const onApplicationChange = useCallback(app_id => parentOnApplicationChange(app_id, setUserInfo), [setUserInfo, parentOnApplicationChange])
  const searchGSM = useCallback(gsm => parentSearchGSM(gsm, setUserInfo, onFieldClear), [setUserInfo, parentSearchGSM, onFieldClear])

  const onSearchCompanyservice = useCallback(Callbacks.onSearchCompanyserviceHandler(searchCallerCompanyuser, setFormValues, user_id, setSelectedUser), [searchCallerCompanyuser, setFormValues, user_id, setSelectedUser])
  const onSearchCompanycontract = useCallback(Callbacks.onSearchCompanycontractHandler(searchCompanycontract, setFormValues, setAuthenticationTypeState), [searchCompanycontract, setFormValues, setAuthenticationTypeState])
  const onSearchCompanyuser = useCallback(Callbacks.onSearchCompanyuserHandler(searchCompanyuser, setFormValues, setSelectedUser), [searchCompanyuser, setFormValues, setSelectedUser])
  const getCompanyuserFilter = useCallback(Callbacks.getCompanyuserFilterHandler(), [])
  const getCompanyuserValidate = useCallback(Callbacks.getCompanyuserValidateHandler(booking_level, self_booking, companyservice_id), [booking_level, self_booking, companyservice_id])
  const handleChangeRidemode = useCallback(() => setFormValues([{ field: 'ridemode_duration', value: undefined }]))

  const hasPassword = !!_.get(companyservice, 'password')
  const companyuser_watchers = [companyuser_id, company_id, companycontract_id, booking_level, self_booking, companyservice_id, caller_companyuser]

  useEffect(() => {
    !!companyservice && setHasCompanyAccess(!companyservice.password || companyservice.password === password)
  }, [HookUtils.getRecordComplete(companyservice), password])

  const phoneValue = _.get(selectedCompanyuser, 'user.auth.gsm') || passenger_number || calling_number || _.get(companyservice, 'contact.gsm')

  const mergedProps = {
    handleChangeRidemode,
    getCompanyuserFilter,
    getCompanyuserValidate,
    companyuser_watchers,
    searchGSM,
    onFieldClear,
    onApplicationChange,
    onSearchCompanyuser,
    onSearchCompanycontract,
    onSearchCompanyservice,
    require_confirmation: _.get(companyservice, 'require_confirmation'),
    calling_number,
    phoneValue,
    guest_booking,
    isManagingRecord,
    companycontract_id,
    company_id,
    authentication_type,
    hasCompanyAccess,
    hasPassword,
    load_type,
    user: user_id,
    commercial_formula,
    application: application_id,
    service: companyservice_id
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
