import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const load_type = hooks.useFieldValue(['load_type'])
  const [{ record_id, isManagingRecord }] = hooks.useFormState()

  const mergedProps = {
    load_type,
    isManagingRecord,
    record_id,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
