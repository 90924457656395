import React, { useCallback } from 'react'
import { hooks, I18n, CDNSVG } from '@front/volcanion'

import { TextComponent, TextField, MenuSelectorField, ModelAutocompleteField, ModelMenuSelectorField, ModelMenuSelectorComponent } from '@front/squirtle'
import { Typography, Box, Collapse, Grid } from '@mui/material'

import UserUtils from '@front/volcanion/utils/user'
import FormatUtils from '@front/squirtle/utils/format'

const Row1 = ({
  searchGSM,
  normalizePhone,
  onSearchCompanyservice,
  onSearchCompanycontract,
  companycontract_id,
  authentication_type,
  hasPassword,
  disabled
}) =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <TextField
        name='user.gsm'
        label={I18n.t('phone.label', { count: 1 })}
        afterChange={searchGSM}
        normalize={normalizePhone}
        debounceTime={250}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'user.companycontract'}
        label={I18n.t('contract.label', { count: 1 })}
        model_name={'companycontract'}
        searchMode='search'
        selectFirst={false}
        afterChange={onSearchCompanycontract}
        loadOnFocus={false}
        selectSingleOption
        debounceTime={250}
        config={{
          mutator: contract => ({ label: _.join([contract?.client_id, contract?.name], '-'), ...contract }),
        }}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        InputProps={{
          inputProps: { autoComplete: "new-password" }
        }}
        getOptionIcon={option => 'circle'}
        getOptionIconProps={option => ({
          base: 'material-icons',
          size: 10,
          sx: { color: FormatUtils.getStatusColor(FormatUtils.getStatus(option)) }
        })
        }
        getOptionProps={option => !!option && ({ disabled: FormatUtils.getStatus(option) !== 'active' })}
        autoFocus
        clearValue={null}
        required
        minQueryLength={1}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'user.companyservice'}
        label={I18n.t('companyservice.label', { count: 1 })}
        model_name={'companyservice'}
        searchMode='search'
        searchQuery={authentication_type !== 'service' ? '=' : 'startsWith'}
        loadOnFocus={!!authentication_type && authentication_type === 'service'}
        autocompleteKey={'client_id'}
        labelKeys={['client_id']}
        multiple={false}
        autocompleteMutator={q => ({
          contract: companycontract_id,
          client_id: authentication_type !== 'service' ? q : { startsWith: q }
        })}
        afterChange={onSearchCompanyservice}
        selectSingleOption
        debounceTime={250}
        InputProps={{
          inputProps: { autoComplete: "new-password" }
        }}
        getOptionIcon={option => 'circle'}
        getOptionIconProps={option => ({
          base: 'material-icons',
          size: 10,
          sx: { color: FormatUtils.getStatusColor(FormatUtils.getStatus(option)) }
        })
        }
        getOptionProps={option => !!option && ({ disabled: FormatUtils.getStatus(option) !== 'active' })}
        required
        disabled={disabled}
      />
    </Grid>
    <Grid item xs hidden={!hasPassword}>
      <TextField
        label={I18n.t('password.label', { count: 1 })}
        name={'user.password'}
        InputProps={{
          inputProps: { autoComplete: "new-password" }
        }}
        disabled={disabled}
      />
    </Grid>
  </Grid>

const Row2 = ({
  company_id,
  require_confirmation,
  load_type,
  user,
  commercial_formula,
  application,
  service,
  handleChangeRidemode,
  disabled
}) =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs >
      <ModelMenuSelectorComponent
        label={I18n.t('company.label', { count: 1 })}
        model_name={'company'}
        labelKeys={['name']}
        value={company_id}
        config={{ ids: [company_id] }}
        multiple={false}
        disabled
      />
    </Grid>
    <Grid item xs >
      <ModelMenuSelectorField
        name={'user.companycontract'}
        label={I18n.t('contract.label', { count: 1 })}
        model_name={'companycontract'}
        labelKeys={['name']}
        searchMode='search'
        loadOnMount
        multiple={false}
        disabled
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'user.companyservice'}
        label={<Box sx={{ display: !!require_confirmation ? 'flex' : 'none' }}>
          <Box sx={{ mr: 1 }}> <CDNSVG name='call_wait' /> </Box>
          <Typography> {I18n.t('companyservice.label', { count: 1 })} </Typography>
        </Box>
        }
        model_name={'companyservice'}
        labelKeys={['name']}
        searchMode='search'
        multiple={false}
        loadOnMount
        disabled
      />
    </Grid>
    <Grid item xs >
      <ModelMenuSelectorField
        name='user.commercial_formula'
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        loadOnMount
        readOnly
        disabled
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'ridemode'}
        label={I18n.t('ride.label', { count: 1 })}
        afterUserChange={handleChangeRidemode}
        model_name={'ridemode'}
        labelKeys={['name_translated']}
        searchMode={'search'}
        model_func='getAuthorized'
        required
        config={{
          sort: ['display_index ASC'],
          forced_filter: {
            load_type,
            user,
            commercial_formula,
            application,
            service
          },
          watchers: [
            load_type,
            user,
            commercial_formula,
            application,
            service
          ],
          validate_filter: useCallback(() => !!load_type && !!application, [load_type, application])
        }}
        disabled={disabled}
      />
    </Grid>
  </Grid >

const Row3 = ({
  phoneValue,
  onSearchCompanyuser,
  onLoadTypeChange,
  guest_booking,
  getCompanyuserFilter,
  getCompanyuserValidate,
  companyuser_watchers,
  disabled
}) =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <ModelMenuSelectorField
        name='user.companyuser'
        label={I18n.t('companyuser.label', { count: 1 })}
        model_name='companyuser'
        searchMode='search'
        labelKeys={['label']}
        disabled={guest_booking === false || !!disabled}
        loadOnFocus
        config={{
          mutator: companyuser => ({
            label: UserUtils.getFormatedName({ ...companyuser?.contact, designation: companyuser?.user?.customerinfo?.name }),
            ...companyuser
          }),
          populate: ['user.customerinfo', 'user.info', 'user.auth', 'contact'],
          validate_filter: getCompanyuserValidate,
          forced_filter: getCompanyuserFilter(...companyuser_watchers),
          watchers: companyuser_watchers
        }}
        afterUserChange={onSearchCompanyuser}
        getOptionIcon={option => 'circle'}
        getOptionIconProps={option => ({
          base: 'material-icons',
          size: 10,
          sx: { color: FormatUtils.getStatusColor(FormatUtils.getStatus(option)) }
        })}
        noneLabel={I18n.t('void.label', { count: 1 })}
        noneFirst
        selectFirst
        getOptionProps={option => !!_.get(option, 'companyuser_id') && ({ disabled: FormatUtils.getStatus(option) !== 'active' })}
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        value={phoneValue}
        label={I18n.t('phone.label', { count: 1 })}
        disabled
      />
    </Grid>
    <Grid item xs hidden>
      <ModelMenuSelectorField
        name='user.application'
        label={I18n.t('application.label', { count: 1 })}
        model_name='application'
        labelKeys={['name']}
        selectFirst
        config={{
          allowAll: true,
          sortBy: ['display_index'],
          sortDirection: ['asc']
        }}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='load_type'
        label={I18n.t('load.label', { count: 1 })}
        options={hooks.useLoads()}
        selectFirst
        afterUserChange={onLoadTypeChange}
        required
        disabled
      />
    </Grid>
  </Grid >

const Row4 = props =>
  <Grid item xs={12}>
    <ModelMenuSelectorField
      name={'user.companycontract'}
      label={I18n.t('address.label', { count: 1 })}
      model_name={'companycontract'}
      config={{ populate: ['contact'] }}
      labelKeys={['contact.address']}
      searchMode='search'
      multiple={false}
      disabled
      required
    />
  </Grid>

const Row5 = props =>
  <Grid item xs={12}>
    <ModelMenuSelectorField
      name={'user.companyservice'}
      label={I18n.t('comment.label', { count: 1 })}
      model_name={'companyservice'}
      config={{
        populate: ['comments.type'],
        mutator: companyservice => ({
          label: _.head(_.compact(
            _.map(_.get(companyservice, 'comments'), comment => (
              _.get(comment, 'type.client_id') === 'service_operator'
                ? _.get(comment, 'text')
                : false
            )))),
          ...companyservice
        })
      }}
      labelKeys={['label']}
      searchMode='search'
      multiple={false}
      disabled
      required
    />
  </Grid>

const B2CSection = ({ hasCompanyAccess, ...props }) => <>
  <Grid container spacing={1} rowSpacing={1.5} alignItems='center'>
    <Row1 {...props} />
  </Grid>
  <Collapse in={hasCompanyAccess}>
    <Grid container spacing={1} rowSpacing={1.5} sx={{ alignItems: 'center', mt: 1 }}>
      <Row2 {...props} />
      <Row3 {...props} />
      <Row4 />
      <Row5 />
    </Grid>
  </Collapse>
</ >
export default React.memo(B2CSection)
