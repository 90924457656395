import { I18n } from '@front/volcanion'
import GeoUtils from '@front/volcanion/utils/geo'

class Callbacks {
  static handleSwapAddress(source, destination, setFormValues) {
    return function swapAddress() {
      setFormValues([
        { field: 'source', value: destination },
        { field: 'destination', value: source },
      ])
    }
  }
  static handleSetFavoriteAddressHandler(openNotification, owner, address, favorite, setFavoriteAddress, toggleFavorite) {
    return async function handleSetFavoriteAddress() {
      try {
        await toggleFavorite({ owner, address: address, favorite: !favorite })
        openNotification(I18n.t(`toast.address.success.${!!favorite ? 'unfavorite' : 'favorite'}`), { variant: 'success' })
        setFavoriteAddress(!favorite)
      } catch (err) {
        openNotification(I18n.t(`toast.address.error.${!favorite ? 'unfavorite' : 'favorite'}`), { variant: 'error' })
      }
    }
  }
  static handleReplaceCommentHandler(openNotification, save_comment, setSaveComment, comment, address) {
    return function handleReplaceComment() {
      if (!!(!save_comment)) {
        if (!!_.get(address, 'address_id') && !!_.get(address, 'comment'))
          openNotification(I18n.t('toast.comment.success.replaced', { old_comment: _.get(address, 'comment'), comment }), { variant: 'success' })
        else
          openNotification(I18n.t('toast.comment.success.saved', { comment }), { variant: 'success' })
      } else {
        if (!!_.get(address, 'address_id') && !!_.get(address, 'comment'))
          openNotification(I18n.t('toast.comment.cancel.replaced'))
        else
          openNotification(I18n.t('toast.comment.cancel.saved'))
      }
      setSaveComment(!save_comment)
    }
  }
  static handleOnAddressResolve(setAddressDetails, setFavoriteAddress, setFormValues, prefix, source, destination, setMeetingPoint, setDriverInstruction) {
    return function addressResolve(details) {
      const prefix_field = prefix_field === 'source' ? 'src' : 'dest'
      const isSame = !!source && !!destination && GeoUtils.isSameAddress(source, destination)

      setFavoriteAddress(_.get(details, 'favorite'))
      setAddressDetails(details)
      setFormValues(_.compact([
        { field: `info.${prefix_field}_comment`, value: _.get(details, 'comment') },
        prefix === 'source' && { field: 'poi', value: _.get(details, 'poi') },
        !!isSame && { field: 'source', meta: { error: true, field_error: I18n.t('error.address.same') } },
        !!isSame && { field: 'destination', meta: { error: true, field_error: I18n.t('error.address.same') } }
      ]))
      if (details?.poi_id === source?.poi_id) {
        setMeetingPoint({
          translated: _.get(details, 'meeting_point_translated'),
          trkey: _.get(details, 'meeting_point_trkey')
        })
        setDriverInstruction({
          translated: _.get(details, 'driver_instruction_translated'),
          trkey: _.get(details, 'driver_instruction_trkey')
        })
      }
    }
  }
  static guestChangeHandler(setFormValues, companyuser, prefix, load_type) {
    return function guestChange(value) {
      const full_name = _.compact([_.get(companyuser, 'user.info.first_name'), _.get(companyuser, 'user.info.last_name')]).join(' ') || _.get(companyuser, 'user.customerinfo.name')
      const address_field = prefix === 'source' ? 'src' : 'dest'
      setFormValues([
        { field: 'guest', value },
        { field: `loads.${address_field}_phone`, value: !!value ? undefined : _.get(companyuser, 'user.auth.gsm') },
        { field: `loads.${address_field}_contact`, value: !!value ? undefined : full_name }
      ])
    }
  }
  static handleIsReservationinfoChangeHandler(poi, setMeetingPoint, setDriverInstruction) {
    return function handleIsReservationinfoChange(value) {
      if (!value) {
        setMeetingPoint({
          translated: _.get(poi, 'meeting_point_translated'),
          trkey: _.get(poi, 'meeting_point_trkey')
        })
        setDriverInstruction({
          translated: _.get(poi, 'driver_instruction_translated'),
          trkey: _.get(poi, 'driver_instruction_trkey')
        })
      }
      else if (!!value) {
        setMeetingPoint({
          translated: _.get(poi, 'type.reservationinfo.arrival_translated'),
          trkey: _.get(poi, 'type.reservationinfo.arrival_trkey')
        })
        setDriverInstruction({
          translated: _.get(poi, 'type.reservationinfo.arrival_translated'),
          trkey: _.get(poi, 'type.reservationinfo.arrival_trkey')
        })
      }
    }
  }
}

export default Callbacks
