import moment from 'moment'

class Callbacks {
  static handleVehicleCountChangeHandler(setFormValues, companyuser_info) {
    return function handleVehicleCountChange(value) {
      const multiple = value > 1
      setFormValues([
        { field: 'fill_by_driver', value: !!multiple },
        { field: 'has_luggage', value: false },
        { field: 'load_count', value: 1 },
        { field: 'guest', value: false },
        { field: 'loads.src_contact', value: !!multiple ? null : companyuser_info?.name },
        { field: 'loads.src_phone', value: !!multiple ? null : companyuser_info?.number }
      ])
    }
  }
  static handleDateShortcutsHandler(setFormValues, requestedAt) {
    return function handleDateShortcuts(e, extra) {
      const { altKey, ctrlKey, key } = e
      if (!altKey && !ctrlKey) {
        switch (key) {
          case 'd':
          case 'D':
            setFormValues([{ field: 'requestedAt', value: moment().add(1, 'day').format() }])
            e.preventDefault()
            break;
          case 'a':
          case 'A':
            setFormValues([{ field: 'requestedAt', value: moment().set({ hour: 0, minute: 0, second: 0 }) }])
            e.preventDefault()
            break;
          case 's':
          case 'S':
            setFormValues([{ field: 'requestedAt', value: moment(requestedAt).add(1, 'day') }])
            e.preventDefault()
            break;
          case 'p':
          case 'P':
            setFormValues([{ field: 'requestedAt', value: requestedAt.subtract(1, 'day') }])
            e.preventDefault()
            break;
        }
      }
    }
  }
  static handleTimeShortcutsHandler(setFormValues, requestedAt, calculatedDeferToValue) {
    return function handleTimeShortcuts(e, extra) {
      const { onChange } = extra
      const { altKey, ctrlKey, key } = e
      const deferTo = calculatedDeferToValue
      const firstPossible = moment(requestedAt).add(moment().diff(moment(deferTo), 'minutes'), 'minutes')
      if (!altKey && !ctrlKey) {
        switch (key) {
          case 'i':
          case 'I':
            onChange(moment().add(1, 'minutes'))
            e.preventDefault()
            break;
          case 'm':
          case 'M':
            e.preventDefault()
            break;
          case 'a':
          case 'A':
            onChange(moment(firstPossible).add(2, 'minute'))
            e.preventDefault()
            break;
        }
      }
      if (ctrlKey) {
        switch (key) {
          case '1':
          case 1:
            onChange(moment(requestedAt).subtract(10, 'minutes'))
            e.preventDefault()
            break;
          case '5':
          case 5:
            onChange(moment(requestedAt).subtract(5, 'minutes'))
            e.preventDefault()
            break;
        }
      }
      else if (altKey) {
        switch (key) {
          case '1':
          case 1:
            onChange(moment(requestedAt).add(10, 'minutes'))
            e.preventDefault()
            break;
          case '5':
          case 5:
            onChange(moment(requestedAt).add(5, 'minutes'))
            e.preventDefault()
            break;
        }
      }
    }
  }
  static handleDateTimeChangeHandler(setFormValues, threshold_RA) {
    return function handleDateTimeChange(value) {
      if (!value) setFormValues([{ field: 'schedule_type', value: null }])
      const schedule_type = moment(value).isAfter(moment().add(threshold_RA, 'minutes')) ? 'planned' : 'immediate'
      setFormValues([{ field: 'schedule_type', value: schedule_type }])

    }
  }
  static handleGetDefertToHandler(calculateDeferTo, setDefertTo) {
    return async function handleGetDefertTo(requestedAt, source, driver_opts, vehicle_opts) {
      const { deferTo } = await calculateDeferTo({ source_address: _.get(source, 'details'), requestedAt, driver_opts, vehicle_opts })
      setDefertTo(deferTo)
    }
  }
  static handleScheduleTypeChangeHandler(setFormValues) {
    return function handleScheduleTypeChange(value) {
      if (value === 'immediate')
        setFormValues([
          { field: 'reservationinfo.name', value: null },
          { field: 'reservationinfo.origin', value: null },
          { field: 'reservationinfo.arrival', value: null }
        ])
    }
  }
  static handleRoundTripChangeHandler(setFormValues, setFormState, displayRoundTrip) {
    return function handleRoundTripChange() {
      setFormState({ displayRoundTrip: !displayRoundTrip })
      setFormValues([
        { field: 'returnAt', value: null }
      ])
    }
  }
}

export default Callbacks
